<template>
  <v-dialog v-model="dialog"
            max-width="400"
            persistent
  >
    <v-card>
      <v-card-text class="pa-4 text-center">
        <v-icon size="100" color="primary">mdi-information-outline</v-icon>
        <h2 class="my-5 text-uppercase">{{ $t('message.confirmation') }}</h2>
        <p class="fs-16"> Voulez-vous vraiment refuser cette enquête ?</p>

        <v-select :items="$store.state.api.raisons"
                  clearable
                  prepend-inner-icon="mdi-text-box-minus"
                  class="pr-10 pl-10"
                  v-model="raisonId"
                  item-value="id"
                  :item-text="item=>{return item.name[$lang]}"
                  label="Raison"/>

        <v-textarea class="pr-10 pl-10"
                    prepend-inner-icon="mdi-comment-processing"
                    label="Remarque"
                    v-model="remark" rows="3"/>

        <div class="py-2">
          <v-btn text
                 color="primary"
                 @click.native="close">
            <v-icon left>mdi-close</v-icon>
            {{ $t('btn.cancel') }}
          </v-btn>
          <v-btn color="primary"
                 depressed
                 @click.native="refuse"
                 :class="$lang==='ar'? 'mr-2' :'ml-2'">
            <v-icon left>mdi-check</v-icon>
            {{ $t('btn.ok') }}
          </v-btn>
        </div>

      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  data: () => ({
    dialog: false,
    raisonId: null,
    remark: null,
    investigationSalepointIds: null,


  }),
  methods: {
    open(investigationSalepointIds) {
      this.raisonId = null
      this.remark = null
      this.dialog = true
      this.investigationSalepointIds = investigationSalepointIds
    },
    close() {
      this.dialog = false
    },
    refuse() {
      this.$Progress.start()
      this.close()
      let data = {
        investigationSalepointIds: this.investigationSalepointIds,
        status: 'temp_refused',
        raisonId: this.raisonId,
        remark: this.remark
      }
      this.$emit('change-status', data)
    }
  }
}
</script>