<template>
  <v-dialog v-model="dialog"
            max-width="400"
            persistent
  >
    <v-card>
      <v-card-text class="pa-4 text-center">
        <v-icon size="100" color="primary">mdi-information-outline</v-icon>
        <h2 class="my-5 text-uppercase">{{ $t('message.confirmation') }}</h2>
        <p class="fs-16"> Voulez-vous vraiment changer l'état de ces réponses ?</p>

        <v-select :items="states"
                  class="pr-10 pl-10"
                  v-model="state"
                  item-value="value"
                  item-text="name"
                  label="État *"/>

        <div class="py-2">
          <v-btn text
                 color="primary"
                 @click.native="close">
            <v-icon left>mdi-close</v-icon>
            {{ $t('btn.cancel') }}
          </v-btn>
          <v-btn color="primary"
                 :disabled="!state"
                 depressed
                 @click.native="save"
                 :class="$lang==='ar'? 'mr-2' :'ml-2'">
            <v-icon left>mdi-check</v-icon>
            {{ $t('btn.ok') }}
          </v-btn>
        </div>

      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  data: () => ({
    dialog: false,
    states: [
      {name: 'Valider', value: 'temp_validated'},
      {name: 'Réfuser', value: 'temp_refused'},
    ],
    state: null,
    investigationSalepointIds: null,
  }),
  methods: {
    open(investigationSalepointIds) {
      this.state = null
      this.dialog = true
      this.investigationSalepointIds = investigationSalepointIds
    },
    close() {
      this.dialog = false
    },
    save() {
      this.$Progress.start()
      this.close()
      let data = {
        investigationSalepointIds: this.investigationSalepointIds,
        state: this.state
      }
      this.$emit('change-status', data)
    }
  }
}
</script>