<template>
  <div>
    <v-container fluid>
      <breadcrumbs :breadcrumbs="breadcrumbs"/>

      <v-row>
        <v-col cols="3">
          <BackofficeNavigation class="sticky-block"/>
        </v-col>

        <v-col cols="9">

          <div v-if="isLoading">
            <v-card class="rounded-lg shadow">
              <v-card-text>
                <v-skeleton-loader type="article,actions"/>
              </v-card-text>
            </v-card>
            <v-card class="rounded-lg shadow mt-5">
              <v-card-text>
                <v-skeleton-loader type="table"/>
              </v-card-text>
            </v-card>
          </div>

          <div v-else>

            <v-card class="rounded-lg shadow">

              <v-card-title>
                {{ $func.setName(analytic.investigation.name, analytic.investigation.campaign.lang) }}
                <v-spacer/>
                <v-btn color="primary"
                       depressed
                       @click="$router.push('/backoffice/final-validation')">
                  <v-icon left>{{ $lang === 'ar' ? 'mdi-arrow-right' : 'mdi-arrow-left' }}</v-icon>
                  {{ $t('btn.back') }}
                </v-btn>
              </v-card-title>

              <v-card-subtitle>
                CAMPAIGNE :
                {{ $func.setName(analytic.investigation.campaign.name, analytic.investigation.campaign.lang) }}
                <v-spacer class="py-1"/>
                <strong class="blue--text">
                  MONTANT :
                  {{ analytic.investigation.amount | toCurrency }}
                </strong>
              </v-card-subtitle>


              <v-card-text>


                <v-list-item two-line>
                  <v-list-item-avatar rounded class="bg-snipper">
                    <v-icon color="blue">mdi-clipboard-text-outline</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>{{ analytic.total }}</v-list-item-title>
                    <v-list-item-subtitle>Total</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-divider/>

                <v-list-item two-line>
                  <v-list-item-avatar rounded class="bg-snipper">
                    <v-icon color="success">mdi-check-circle</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>Réponses validées</v-list-item-title>
                    <v-list-item-subtitle>Total : {{ analytic.totalValidated }}</v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <div class="text-right">
                      <strong>{{ analytic.percentValidated }}%</strong>
                      <v-progress-linear
                          rounded
                          color="blue"
                          height="5"
                          :value="analytic.percentValidated"
                          class="w-150"
                          striped
                      ></v-progress-linear>
                    </div>
                  </v-list-item-action>
                </v-list-item>

                <v-divider/>

                <v-list-item two-line>
                  <v-list-item-avatar rounded class="bg-snipper">
                    <v-icon color="error">mdi-close-circle</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>Réponses réfusées</v-list-item-title>
                    <v-list-item-subtitle>Total : {{ analytic.totalRefused }}</v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <div class="text-right">
                      <strong>{{ analytic.percentRefused }}%</strong>
                      <v-progress-linear
                          rounded
                          color="blue"
                          height="5"
                          :value="analytic.percentRefused"
                          class="w-150"
                          striped
                      ></v-progress-linear>
                    </div>
                  </v-list-item-action>
                </v-list-item>


              </v-card-text>
            </v-card>

            <v-card class="rounded-lg shadow mt-5">


              <v-overlay :value="overlay"
                         absolute
                         :color="$vuetify.theme.dark ?'secondary':'white'">
                <v-progress-circular
                    indeterminate
                    size="60"
                    color="primary"
                ></v-progress-circular>
              </v-overlay>


              <v-card-title>

                <v-spacer/>

                <Refresh @refresh="showTemporaryInvestigations"/>

                <v-menu
                    transition="slide-x-transition"
                    bottom
                    right
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs"
                           :disabled="! investigationSalepointIds.length"
                           class="transform-none blue--text"
                           v-on="on"
                           depressed>
                      Actions
                      <v-icon right>mdi-chevron-down</v-icon>
                    </v-btn>
                  </template>

                  <v-list dense>

                    <v-list-item @click="accepte">
                      <v-list-item-icon>
                        <v-icon color="success">mdi-check</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>accepter</v-list-item-title>
                    </v-list-item>

                    <v-list-item @click="changeState">
                      <v-list-item-icon>
                        <v-icon color="warning">mdi-reload</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Changer l'état</v-list-item-title>
                    </v-list-item>

                  </v-list>
                </v-menu>


              </v-card-title>

              <v-divider/>

              <div v-if="tasks.length">

                <v-card-text class="pa-0">
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                      <tr>

                        <th>
                          <v-icon :color=" investigationSalepointIds.length > 0 ? 'blue' : ''"
                                  class="pa-0 mt-0"
                                  hide-details
                                  @mousedown.prevent
                                  @click="toggleAll">
                            {{ icon }}
                          </v-icon>
                        </th>

                        <th>Point de vente</th>
                        <th>Date sync</th>
                        <th>Status</th>
                        <th>Actions</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="(item,i) in tasks"
                          :key="i"
                          :class=" investigationSalepointIds.includes(item.id) ? 'bg-snipper' : ''"
                      >

                        <td>
                          <v-checkbox hide-details
                                      multiple
                                      v-model=" investigationSalepointIds"
                                      :value="item.id"
                                      color="blue"
                                      class="pt-0 mt-0"></v-checkbox>
                        </td>

                        <td>
                       <span class="d-flex text-no-wrap cursor-pointer"
                             @click="showSalepoint(item.salepoint)">
                         {{ item.salepoint.name }}
                         <v-icon color="blue" small right>mdi-plus</v-icon>
                       </span>
                        </td>

                        <td>
                          {{ item.syncronized_at }}
                        </td>

                        <td>
                          <v-chip small :color="item.status === 'temp_validated' ? 'success': 'error'">
                            {{ item.status === 'temp_validated' ? 'Temp validée' : 'Temp réfusée' }}
                          </v-chip>
                        </td>

                        <td>
                          <v-btn color="blue" icon
                                 @click="showResponse(item)">
                            <v-icon>mdi-plus-circle</v-icon>
                          </v-btn>
                        </td>

                      </tr>
                      </tbody>
                    </template>
                  </v-simple-table>

                </v-card-text>

                <v-divider v-if="total > $totalPerPage"/>

                <v-card-actions v-if="total > $totalPerPage">
                  <div class="w-100">
                    <v-select dense filled
                              hide-details
                              :items="$perPage"
                              v-model="perPage"></v-select>
                  </div>
                  <v-spacer/>
                  <v-pagination total-visible="10"
                                circle
                                v-model="pagination.current"
                                :length="pagination.total"
                                @input="showTemporaryInvestigations"></v-pagination>
                </v-card-actions>

              </div>

              <v-card-text v-else>
                <NoResult size="200"/>
              </v-card-text>

            </v-card>
          </div>


        </v-col>

      </v-row>


    </v-container>

    <SalepointDialog ref="salepointDialog"/>

    <ChangeStateDialog ref="changeStateDialog"
                       @change-status="changeStatus($event)"/>

    <ConfirmDialog ref="confirmDialog"/>

    <ResponsesDialog @change-status="changeStatus($event)"
                     ref="responsesDialog"/>

  </div>
</template>

<script>

import BackofficeNavigation from "@/views/backoffice/components/BackofficeNavigation";
import ResponsesDialog from "@/views/backoffice/components/ResponsesDialog";
import {HTTP} from "@/http-common";
import ChangeStateDialog from "@/views/backoffice/finalValidation/components/ChangeStateDialog";

export default {
  components: {ChangeStateDialog, BackofficeNavigation, ResponsesDialog},
  data() {
    return {
      overlay: false,
      isLoading: false,
      tasks: [],
      analytic: null,
      total: 0,
      perPage: 10,
      pagination: {
        current: 1,
        total: 0
      },

      investigationSalepointIds: []
    }
  },
  methods: {
    showTemporaryInvestigations() {
      this.$Progress.start()
      this.investigationSalepointIds = []
      this.isLoading = true
      const id = this.$route.params.id
      HTTP.get('/backoffice/investigations/' + id + '/temporary/show?page=' + this.pagination.current, {
        params: {
          perPage: this.perPage,
        }
      }).then(res => {
        this.pagination.current = res.data.data.items.current_page;
        this.pagination.total = res.data.data.items.last_page;
        this.total = res.data.data.items.total;

        this.tasks = res.data.data.items.data
        this.analytic = res.data.data.analytic
        this.$Progress.finish()
        this.isLoading = false
      }).catch(err => {
        this.$Progress.fail()
        this.$errorMessage = this.$i18n.t('message.catchError')
        this.isLoading = false
        console.log(err)
      })
    },
    showSalepoint(data) {
      this.$refs.salepointDialog.open(data)
    },
    toggleAll() {
      if (this.investigationSalepointIds.length === this.tasks.length) {
        this.investigationSalepointIds = []
      } else {
        this.investigationSalepointIds = this.tasks.map(el => el.id)
      }
    },

    async accepte() {

      let selectedList = this.tasks.filter(el => {
        return this.investigationSalepointIds.includes(el.id)
      })

      let salepointWinning = selectedList.filter(el => el.status === 'temp_validated')

      let total = salepointWinning.length
      let amount = this.$options.filters.toCurrency(this.analytic.investigation.amount * salepointWinning.length)

      let M1 = '<strong class="d-block fs-16">Total des POS gagnantes : <span class="text-primary text-decoration-underline">' + total + '</span></strong>'
      let M2 = '<strong class="d-block pt-2 fs-16">Montant total : <span class="text-primary text-decoration-underline">' + amount + '</span> </strong>'

      if (await this.$refs.confirmDialog.open('', 'Voulez-vous vraiment accepté l\'état de ces réponses ?', '', M1 + M2)) {
        let data = {
          investigationSalepointIds: this.investigationSalepointIds,
          state: 'accepted',
        }
        this.changeStatus(data)
      }
    },

    changeStatus(data) {
      this.overlay = true
      HTTP.post('/backoffice/investigations/temporary/change-state', {
        investigationSalepointIds: data.investigationSalepointIds,
        state: data.state,
        status: data.status,
      }).then(() => {
        this.$Progress.finish()
        this.overlay = false
        this.$successMessage = this.$i18n.t('message.succssUpdate')
        this.showTemporaryInvestigations()
      }).catch(err => {
        this.$Progress.fail()
        this.$errorMessage = this.$i18n.t('message.catchError')
        this.overlay = false
        console.log(err)
      })
    },

    changeState() {
      this.$refs.changeStateDialog.open(this.investigationSalepointIds)
    },
    showResponse(data) {
      this.$refs.responsesDialog.open(data)
    },

  },
  created() {
    this.showTemporaryInvestigations()
  },
  watch: {
    perPage() {
      this.showTemporaryInvestigations()
    }
  },
  computed: {
    breadcrumbs() {
      return [
        {to: '/#', text: 'breadcrumb.backoffice', disabled: true},
        {to: '/#', text: 'backoffice.finalValidation', disabled: true},
      ]
    },
    icon() {
      if (this.investigationSalepointIds.length === this.tasks.length) return 'mdi-close-box'
      if (this.investigationSalepointIds.length > 0 && this.investigationSalepointIds.length !== this.tasks.length) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    },
  }
}
</script>

<style scoped>
</style>