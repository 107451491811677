<template>
  <v-dialog max-width="1000"
            v-model="dialog">

    <v-card>


      <v-overlay :value="overlay"
                 absolute
                 :color="$vuetify.theme.dark ?'secondary':'white'">
        <v-progress-circular
            indeterminate
            size="60"
            color="primary"
        ></v-progress-circular>
      </v-overlay>


      <v-card-title>
        <v-spacer/>
        <v-icon @click="dialog=false">mdi-close</v-icon>
      </v-card-title>
      <v-divider/>

      <v-card-text v-if="isLoading" class="pa-4">
        <v-row>
          <v-col>
            <v-skeleton-loader type="card,article"/>
          </v-col>
          <v-col>
            <v-skeleton-loader type="article,article,article"/>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-text class="pb-0" v-for="(response,i) in responses" :key="i">

        <v-row>

          <v-col cols="6" class="b-r">

            <div class="bg-snipper pa-4 text-center font-weight-bold fs-17 blue--text rounded-b-pill">
              Intitulé
            </div>

            <div class="py-10">

              <div class="d-block mb-4">
                <span class="text--primary font-weight-bold">Type de question :</span>
                {{ response.task.question_type.name[$lang] }}
                <v-icon right color="blue" class="mt--4">{{ response.task.question_type.icon }}</v-icon>
              </div>

              <div class="d-block mb-4">
                <span class="text--primary font-weight-bold">Type de réponse :</span>
                {{ response.task.answer_type.name[$lang] }}
                <v-icon right color="blue" class="mt--4">{{ response.task.answer_type.icon }}</v-icon>
              </div>

              <div class="d-block mb-4" v-if="lang.includes('fr')">
                <span class="text--primary font-weight-bold">Question (FR) :</span>
                {{ response.question.question['fr'] }}
              </div>

              <div class="d-block mb-4" v-if="lang.includes('en')">
                <span class="text--primary font-weight-bold">Question (EN) :</span>
                {{ response.question.question['en'] }}
              </div>

              <div class="d-block mb-4" v-if="lang.includes('ar')">
                <span class="text--primary font-weight-bold">Question (AR) :</span>
                {{ response.question.question['ar'] }}
              </div>

              <div class="d-block d-flex mb-4">
                <span class="text--primary font-weight-bold">Fichier:</span>
                <v-chip color="success" small link class="mx-2" v-if="response.task.file">
                  <v-icon small left>mdi-link</v-icon>
                  {{ response.task.file }}
                </v-chip>
                <span class="mx-2" v-else>-</span>
              </div>


              <div v-if="response.task.question_type.key === 'condition'">
                <div class="d-block mb-4">
                  <span class="text--primary font-weight-bold">Conditions :</span>
                </div>

                <v-simple-table class="bg-snipper table-border">
                  <template v-slot:default>
                    <thead>
                    <tr>
                      <th>Code</th>
                      <th>Nom</th>
                      <th>Box</th>
                      <th>Qauntité</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr
                        v-for="(condition,i) in response.question.conditions"
                        :key="i"
                    >
                      <td>{{ condition.product.code }}</td>
                      <td>{{ condition.product.name }}</td>
                      <td>
                        <v-icon color="success" dense v-if="condition.is_box">mdi-check</v-icon>
                        <v-icon color="red" dense v-else>mdi-close</v-icon>
                      </td>
                      <td>{{ condition.quantity }}</td>
                    </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </div>

              <div v-if="response.task.answer_type.key === 'radio' || response.task.answer_type.key === 'checkbox'">

                <div class="d-block mb-4">
                  <span class="text--primary font-weight-bold">Options :</span>
                </div>


                <v-row v-for="(option,i) in response.question.options" :key="i" class="table-border my-2">

                  <v-col v-if="lang.includes('fr')">
                    <v-text-field :prepend-inner-icon="response.task.answer_type.icon"
                                  readonly
                                  dense
                                  disabled
                                  hint="FR"
                                  persistent-hint
                                  :label="'Option ' +(i+1)"
                                  v-model="option.option['fr'] "/>
                  </v-col>

                  <v-col v-if="lang.includes('en')">
                    <v-text-field :prepend-inner-icon="response.task.answer_type.icon"
                                  readonly
                                  dense
                                  disabled
                                  hint="EN"
                                  persistent-hint
                                  :label="'Option ' +(i+1)"
                                  v-model="option.option['en'] "/>
                  </v-col>

                  <v-col v-if="lang.includes('ar')">
                    <v-text-field :prepend-inner-icon="response.task.answer_type.icon"
                                  readonly
                                  dense
                                  disabled
                                  hint="AR"
                                  persistent-hint
                                  :label="'Option ' +(i+1)"
                                  v-model="option.option['ar'] "/>
                  </v-col>

                </v-row>

              </div>

            </div>
          </v-col>

          <v-col cols="6">
            <div class="bg-snipper pa-4 text-center font-weight-bold fs-17 blue--text rounded-b-pill">
              Réponse
            </div>
            {{ response.response }}
          </v-col>

        </v-row>

      </v-card-text>

      <v-card-text v-if="!responses.length && !isLoading">
        <NoResult size="200"/>
      </v-card-text>

      <v-divider/>

      <v-card-actions v-if="!isLoading && withAction && responses.length">
        <v-btn depressed color="error" class="mr-2"
               @click="refuse">
          <v-icon left>mdi-close</v-icon>
          Réfuser
        </v-btn>
        <v-spacer/>
        <v-btn depressed color="success" @click="validate">
          <v-icon left>mdi-check</v-icon>
          Valider
        </v-btn>
      </v-card-actions>

    </v-card>

    <vue-easy-lightbox escDisabled
                       moveDisabled
                       :visible="visible"
                       :imgs="image"
                       :index="index"
                       @hide="visible = false"></vue-easy-lightbox>

    <ConfirmDialog ref="confirmDialog"/>

    <RefuseDialog ref="refuseDialog"
                  @change-status="[close(),$emit('change-status',$event)]"/>

  </v-dialog>

</template>

<script>


import {HTTP} from "@/http-common";
import RefuseDialog from "@/views/backoffice/tempValidation/components/RefuseDialog";

export default {
  components: {RefuseDialog},
  props: ['withAction'],
  data() {
    return {
      overlay: false,
      isLoading: false,
      dialog: false,
      responses: [],
      lang: [],
      investigationSalepointIds: [],


      /**Lightbox**/
      image: '',
      visible: false,
      index: 0,
    }
  },
  methods: {
    open(item) {
      this.responses = []
      this.lang = item.investigation.campaign.lang
      this.investigationSalepointIds = [item.id]

      this.dialog = true
      this.isLoading = true
      this.$Progress.start()
      HTTP.get('/backoffice/investigations/responses', {
        params: {
          salepointId: item.salepoint_id,
          investigationId: item.investigation_id
        }
      }).then(res => {
        this.isLoading = false
        this.responses = res.data.data
        this.$Progress.finish()
      }).catch(err => {
        this.isLoading = false
        this.$errorMessage = this.$i18n.t('message.catchError')
        this.$Progress.fail()
        console.log(err)
      })
    },
    close() {
      this.dialog = false
    },
    async validate() {
      if (await this.$refs.confirmDialog.open()) {
        this.dialog = false
        let data = {
          status: 'temp_validated',
          investigationSalepointIds: this.investigationSalepointIds,
        }
        this.$emit('change-status', data)
      }
    },
    refuse() {
      this.$refs.refuseDialog.open(this.investigationSalepointIds)
    },
  }
}
</script>

<style scoped>

</style>