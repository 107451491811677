<template>
  <div>
    <v-card class="rounded-lg shadow">
      <v-list>

        <v-list-item color="primary"
                     class="translateY nav-list"
                     :class="$route.meta.key === item.key ? 'border--primary v-list-item--active primary--text' : ''"
                     v-for="(item, i) in items"
                     :key="i"
                     @click="$router.push(item.to)">
          <v-list-item-icon>
            <v-icon color="primary" dense v-text="item.icon"></v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="$t(item.text)"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>

      </v-list>
    </v-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      items: [
        {
          text: 'backoffice.tempValidation',
          icon: 'mdi-clock-check-outline',
          to: '/backoffice',
          key: 'tempValidation'
        },
        {
          text: 'backoffice.finalValidation',
          icon: 'mdi-clipboard-check-outline',
          to: '/backoffice/final-validation',
          key: 'finalValidation'
        },
        {
          text: 'backoffice.completed',
          icon: 'mdi-folder-text-outline',
          to: '/backoffice/completed',
          key: 'completed'
        },
      ],
    }
  },
}
</script>

<style scoped>

</style>